<template>
  <!-- eslint-disable -->
  <div id="pdfvuer">
    <div v-if="loaded && pdfdata">
      <pdf
        v-for="i in numPages"
        :key="i"
        :src="pdfdata"
        :page="i"
        class="d-block mb-4"
        style="width: 100%"
      />
      <!-- <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i"
        :scale.sync="scale" style="width:100%;margin:10px auto;">
        <template slot="loading">
          loading content here...
        </template>
      </pdf> -->
    </div>
    <div v-else class="loading-content">
      loading content, please wait ...
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import pdf from 'vue-pdf'
import 'pdfjs-dist/build/pdf.worker.entry' // To be uncommented if you get error: Error: Setting up fake worker failed: "window.pdfjsWorker is undefined".

export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  components: {
    pdf: pdf
  },
  data () {
    return {
      loaded: false,
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width'
    }
  },
  computed: {
    formattedZoom () {
      return Number.parseInt(this.scale * 100);
    },
  },
  created () {
    this.loaded = false
    this.$store.dispatch('GET_FILE', {
      file: this.src
    }).then((res) => {
      if (res.status) {
        this.pdfdata = pdf.createLoadingTask(res.data)
        setTimeout(() => {
          this.getPdf()
        }, 150)
      } else {
        console.log('failed')
      }
    })
  },
  watch: {
    show: function (s) {
      if(s) {
        // this.$store.dispatch('GET_FILE', {
        //   file: '../cdn/images/b7y8lr7oekl3xfik2.pdf'
        // }).then((res) => {
        //   console.log(res)
        //   this.getPdf(res)
        // })
      }
    },
    page: function (p) {
      if( window.pageYOffset <= this.findPos(document.getElementById(p)) || ( document.getElementById(p+1) && window.pageYOffset >= this.findPos(document.getElementById(p+1)) )) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    }
  },
  methods: {
    getPdf () {
      this.loaded = true
      this.pdfdata.promise.then(pdf => {
        this.numPages = pdf.numPages
      });
    },
    findPos(obj) {
      if (obj) {
        return obj.offsetTop;
      }
    }
  }
}
</script>
<style>
  #buttons {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  /* Page content */
  .content {
    padding: 10px;
  }
  .loading-content {
    padding: 30px 16px;
    text-align: center;
  }
  .textLayer {
    display: none!important;
  }
</style>
